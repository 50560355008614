import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  FileUploadOutlinedIcon,
} from '@mtb/ui';
import { useTranslation } from '../../../../services/i18n';

export const OpenLocal = () => {
  const [t] = useTranslation();

  return (
    <List>
      <ListItemButton
        sx={{ borderRadius: 1 }}
        onClick={() => console.log('Open Local File')}>
        <ListItemIcon>
          <FileUploadOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography
            noWrap
            variant="title-sm"
            weight="regular">
            {t('connection.openLocal')}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </List>
  );
};
