import type { MtbPlatform } from '../../types';
import { initInterfaceVersions, lowestVersion } from './init-remote-module';

const scriptElementPromiseMap = new Map<string, Promise<void>>();

async function createRemoteModuleScriptElement(url: string): Promise<void> {
  if (scriptElementPromiseMap.has(url)) {
    return scriptElementPromiseMap.get(url) ?? Promise.resolve();
  }

  let resolve: (value?: void) => void;
  let reject: (reason?: Error) => void;
  const promise = new Promise<void>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  scriptElementPromiseMap.set(url, promise);

  const scriptElement = document.createElement('script');
  scriptElement.src = url;
  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  scriptElement.onload = () => resolve();
  scriptElement.onerror = () => {
    scriptElement.remove();
    if (process.env.NODE_ENV === 'development') {
      const errorMessage = `
Failed to load remote module:
 - Did you forget to start the remote module?
 - Did you ensure that the module's SSL certificate is valid by opening the URL: ${url}
`;
      console.error(errorMessage);
    } else {
      reject(new Error('Failed to load remote module.'));
    }
  };
  document.head.appendChild(scriptElement);
  return promise;
}

function createRemoteModuleLoader(scope: string, module: string, cdnUrl = '') {
  return async () => {
    const url = `${cdnUrl}/platform-remote-entry.js`;
    await createRemoteModuleScriptElement(url);

    // @ts-expect-error These are webpack globals that are injected by the module federation plugin.
    const [init_sharing, share_scopes] = [__webpack_init_sharing__, __webpack_share_scopes__];

    // Initializes the shared scope. Fills it with known provided modules from this build and all remotes
    await init_sharing('default');
    // @ts-expect-error TS doesn't like the spread args here
    const container = window[scope];
    // Initialize the container, it may provide shared modules
    await container.init(share_scopes.default);
    const Module = (await container.get(module))() as MtbPlatform.RemoteModuleInterface;
    return initInterfaceVersions[`v${Module?.version || lowestVersion}`](scope, module, Module);
  };
}

export default createRemoteModuleLoader;
export { createRemoteModuleLoader };
