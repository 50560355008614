import React, { useMemo } from 'react';
import {
  Typography,
  Stack,
  Avatar,
  Spacer,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  LogoutIcon,
} from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS } from '../../../../constants';
import { useTranslation } from '../../../../services/i18n';
import { useStorageExplorer } from '../../hooks';
import { StorageExplorerPickerAlert } from '../../StorageExplorerPickerAlert';

export const ProviderSettings = () => {
  const [t] = useTranslation();
  const { type } = useStorageExplorer();

  const providerTitle = useMemo(
    () => ({
      [STORAGE_PROVIDER_KEYS.ONE_DRIVE]   : t('connection.oneDriveSetup'),
      [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE]: t('connection.googleDriveSetup'),
    })[type],
    [t, type],
  );

  return (
    <Stack>
      <Typography
        gutterBottom
        variant="title-xl"
        weight="semibold">
        {providerTitle}
      </Typography>
      <Stack
        gap={2}
        ml={3}
        mt={3}>
        <FormControl as="fieldset">
          <Typography
            as="legend"
            variant="title-md"
            weight="semibold">
            {t('connection.account')}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            flex="1 0 auto"
            flexWrap="wrap"
            gap={1}
            m={2}>
            <Stack
              alignItems="center"
              direction="row"
              gap={1}
              mr={1}>
              <Avatar
                alt={'Foobar'}
                data-testid="account-avatar" />
              <Stack>
                <Typography
                  data-testid="accont-display-name"
                  variant="title-sm">
                  {'Foo Bar'}
                </Typography>
                <Typography data-testid="account-email">{'foobar@minitab.com'}</Typography>
              </Stack>
            </Stack>
            <Spacer />
            <Button
              color="default"
              variant="contained">
              <LogoutIcon />
              {t('connection.signOut')}
            </Button>
          </Stack>
        </FormControl>
        <FormControl as="fieldset">
          <Typography
            as="legend"
            variant="title-md"
            weight="semibold">
            {t('connection.settings')}
          </Typography>
          <Stack
            alignItems="center"
            data-testid="account-info"
            direction="row"
            flex="1 0 auto"
            flexWrap="wrap"
            gap={1}
            m={2}>
            <StorageExplorerPickerAlert />
            <FormControlLabel
              control={<Checkbox />}
              label={
                <>
                  <Typography
                    color="text.primary"
                    variant="title-sm"
                    weight="semibold">
                    {t('connection.saveNewFilesTo')}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    variant="title-sm"
                    weight="regular">
                    {'Foo/Bar/Baz'}
                  </Typography>
                </>
              }
              value="end" />
            <Spacer />
            <Button
              color="default"
              variant="contained">
              {t('connection.change')}
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
};
