import type { ModuleConfig } from '../types';
import { RemoteDataCenter, loadRemoteDataCenter } from './remote';

const CONFIG: ModuleConfig = {
  key  : 'DATACENTER',
  title: 'datacenter',
  icon : 'DatabaseIcon',
  card : {
    title      : 'datacenter',
    description: 'dataCenterDescription',
    tagline    : 'dataSource',
    icon       : 'DatabaseIcon',
    heroIcon   : 'DataCenterHeroIcon',
  },
  preloadRemoteModule: loadRemoteDataCenter,
  remoteModule       : RemoteDataCenter,
  storage            : {
    defaultProjectName: 'datacenterDefaultProject',
    defaultExtension  : '.mdpx',
    defaultPlaneState : {},
    filter            : ['.csv', '.dat', '.mtw', '.mwx', '.txt', '.xls', '.xlsx'],
    appRailFilter     : ['.csv', '.dat', '.mtw', '.mwx', '.txt', '.xls', '.xlsx'],
    passthroughFilter : ['.csv', '.dat', '.mtw', '.mwx', '.txt', '.xls', '.xlsx'],
  },
};

export default CONFIG;
