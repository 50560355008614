import React from 'react';
import { Stack, Typography } from '@mtb/ui';
import { StorageExplorerFileStatus } from './StorageExplorerFileStatus';

const createRandomString = (minLength, maxLength) => {
  const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  return Array.from({ length }, () => String.fromCharCode(Math.floor(Math.random() * 26) + 97)).join('');
};

/**
 * The Skeleton items to display when loading, we generate random strings for the name, owner, and lastAccessedElapsedTime
 * since the skeletons will take up the dimensions of the content. This helps create skeletons that are more visually
 * consistent with the actual content and it's variations in dimensions.
 */
export const SKELETON_ITEMS = Array.from({ length: 25 }, (_, i) => ({
  id                     : `skeleton-${i}`,
  name                   : createRandomString(8, 37),
  displayName            : createRandomString(8, 37),
  folder                 : false,
  lastAccessedElapsedTime: createRandomString(9, 27),
  createdBy              : {
    id         : `skeleton-created-by-${i}`,
    name       : createRandomString(8, 37),
    email      : createRandomString(8, 37),
    displayName: createRandomString(8, 37),
  },
}));

export const DEFAULT_COLUMNS = /** @type {import('@').StorageExplorerTableColumns} */ [
  {
    id    : 'name',
    label : 'connection.name',
    render: row => (
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        title={row?.name}>
        {row?.icon}
        <Typography noWrap>{row?.name}</Typography>
      </Stack>
    ),
    width: 300,
  },
  {
    id    : 'lastAccessed',
    label : 'connection.lastModified',
    render: row => <Typography noWrap>{row?.lastAccessedElapsedTime}</Typography>,
    width : 150,
    align : 'left',
  },
  {
    id    : 'owner',
    label : 'connection.owner',
    render: row => <Typography noWrap>{row?.createdBy?.name}</Typography>,
    width : 175,
    align : 'left',
  },
  {
    id    : 'checkedOut',
    label : '',
    render: row => {
      // TODO
      row.inView && <StorageExplorerFileStatus item={row} />;
    },
    width   : 150,
    disabled: true,
    align   : 'right',
  },
];
