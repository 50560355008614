import React, { Children, useMemo, useCallback } from 'react';
import {
  ListItemButton,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../../constants';
import { useStorageExplorer } from '../../hooks';

export const CloudProviderHeader = ({ children, title, type }) => {
  const child = Children.only(children);
  const { type: explorerType, setType, category: explorerCategory, setCategory, disableSetup } = useStorageExplorer();

  const isSelected = useMemo(
    () => (
      // swap (explorerType === type) to (loggedInProviders.includes(type))
      explorerType === type && explorerCategory === CLOUD_STORAGE_CATEGORIES.NONE
    ),
    [explorerType, type, explorerCategory],
  );

  const handleTypeChange = useCallback(
    () => {
      setType(type);
      setCategory(CLOUD_STORAGE_CATEGORIES.NONE);
    },
    [type, setType, setCategory],
  );

  return (
    <ListItemButton
      selected={isSelected}
      sx={{
        borderRadius: 1,
        ...(disableSetup && { pointerEvents: 'none' }),
      }}
      onClick={handleTypeChange}>
      <ListItemIcon>
        {child}
      </ListItemIcon>
      <ListItemText>
        <Typography
          noWrap
          variant="title-sm"
          weight="regular">
          {title}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};
