import { createElement } from 'react';
import CenteredCircularProgress from '../../utils/centered-circular-progress';
import withErrorBoundary from '../../utils/error-boundary';
import lazy from '../../utils/lazy';
import createRemoteModuleLoader from '../../utils/load-remote-module';
import remoteModuleUrlConfigs from '../../utils/remote-url-configs';
import withSuspense from '../../utils/with-suspense';

const loadRemoteWorkspaceOnline = createRemoteModuleLoader('mtb_workspace', 'WSO', remoteModuleUrlConfigs.WSO);

const LazyRemoteWorkspaceOnline = lazy(loadRemoteWorkspaceOnline);

const RemoteWorkspaceOnline = withErrorBoundary(
  withSuspense(LazyRemoteWorkspaceOnline, () => createElement(CenteredCircularProgress)),
  'RemoteWorkspaceOnline',
);

export { RemoteWorkspaceOnline, loadRemoteWorkspaceOnline };
