import type PlatformModule from '../services/platform-module';
import type { ComponentType, PropsWithChildren } from 'react';
import { createElement, useRef } from 'react';
import platformCore from '..';

export type WithPlatformProps = { planeId: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withPlatform = (Component: ComponentType<any>) => {
  return (props: PropsWithChildren<WithPlatformProps>) => {
    const platformModule = useRef<PlatformModule | undefined>(platformCore.createPlatformModule(props.planeId));

    if (!platformModule.current) {
      throw new Error('PlatformModule could not be initialized');
    }

    return createElement(Component, {
      ...props,
      // TODO: Remove this once we update reporting tool
      plane   : platformModule.current,
      platform: platformModule.current,
    } as React.Attributes);
  };
};

export default withPlatform;
