import type { ModuleConfig } from '../types';
import { RemoteReportingTool, loadRemoteReportingTool } from './remote';

const CONFIG: ModuleConfig = {
  key  : 'REPORTING_TOOL',
  title: 'reportingtool',
  icon : 'MinitabConnectIcon',
  card : {
    title      : 'reportingtool',
    description: 'reportingToolDescription',
    tagline    : 'report',
    icon       : 'MinitabConnectIcon',
    heroIcon   : 'ReportingToolHeroIcon',
  },
  preloadRemoteModule: loadRemoteReportingTool,
  remoteModule       : RemoteReportingTool,
  storage            : {
    defaultProjectName: 'reportingToolDefaultProject',
    defaultExtension  : '.mrpt',
    defaultPlaneState : { isDirty: false },
    filter            : ['.mrpt'],
    appRailFilter     : ['.mtw', '.mwx'],
    passthroughFilter : ['.mtw', '.mwx'],
  },
};

export default CONFIG;
