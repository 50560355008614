import { useSyncExternalStore } from '@mtb/ui';
import { cloudStorageStore } from '../services/store';

/**
 * @returns {ReturnType<import('../services/store').CloudStorageStoreType['getSnapshot']>}
 */

export function useCloudStorageStore() {
  return useSyncExternalStore(
    listener => cloudStorageStore.subscribe(listener),
    () => cloudStorageStore.getSnapshot(),
  );
}
