import React from 'react';
import { useSyncExternalStore } from '@mtb/ui';
import { createInstance } from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { Trans as BaseTrans } from './third-party/react-i18next/Trans';

const i18n = createInstance();

const namespace = /** @type {const} */ ('connection');
const knownTranslations = /** @type {const} */ (['de', 'en', 'es', 'fr', 'ja', 'ko', 'pt-BR', 'zh-CN']);
const resources = knownTranslations.reduce(
  (acc, lang) => ({
    ...acc,
    [lang]: () => {
      try {
        return require(`../locales/${lang}/${namespace}.json`);
      } catch {
        console.error(`failed to load: ../locales/${lang}/${namespace}.json`);
      }
    },
  }),
  {},
);

export const DEFAULT_LOCALE = {
  code              : 'en',
  region            : 'United States',
  translation       : 'en-US',
  currency          : 'USD',
  language          : 'English',
  decimalSeparator  : '.',
  listSeparator     : ',',
  groupSeparator    : ',',
  translationLang   : 'en',
  translationCountry: 'US',
  localeLang        : 'en',
  localeCountry     : 'US',
};

/**
 * @param {typeof knownTranslations[number]} language
 */
export const init18n = (language = DEFAULT_LOCALE.translation) => {
  return i18n.use(ChainedBackend).init({
    backend: {
      backends: [
        resourcesToBackend(async (language, namespace, callback) => {
          try {
            const resource = await resources[language]?.();
            if (!resource) {
              const langOnly = language.split('-')[0];
              if (!knownTranslations.some(t => t.startsWith(langOnly))) {
                throw new Error(`Resources could not be loaded! language: ${language}, ns: ${namespace}`);
              }
            }
            callback(null, resource ?? {});
          } catch (error) {
            callback(error, null);
          }
        }),
      ],
    },
    react: {
      nsMode     : 'fallback',
      bindI18n   : 'languageChanged loaded',
      wait       : true,
      useSuspense: false,
    },
    lng          : language,
    fallbackLng  : 'en',
    ns           : namespace,
    fallbackNS   : namespace,
    defaultNS    : namespace,
    wait         : true,
    interpolation: {
      escapeValue: false,
    },
    format                : value => value,
    parseMissingKeyHandler: s =>
      s.length ? process.env.NODE_ENV !== 'production' ? `[${s}]` : <span className="mtb-ellipsis" /> : '',
  });
};

/**
 * @param {typeof knownTranslations[number]} language
 */
export function changeLanguage(language) {
  if (!knownTranslations.includes(language) && knownTranslations.some(t => t.startsWith(language))) {
    language = knownTranslations.find(t => t.startsWith(language));
  }
  if (!i18n.isInitialized) {
    return init18n(language);
  }
  return new Promise((res, _rej) => {
    i18n.changeLanguage(language, err => {
      if (err) {
        console.error(err);
      }
      res();
    });
  });
}

export function preloadNamespaces(namespaces) {
  return i18n.loadNamespaces(namespaces);
}

export function getLoadedNamespaces() {
  return i18n.options.ns;
}

/**
 * @returns {readonly [import('i18next').TFunction<typeof namespace>, typeof i18n, boolean] & { t: import('i18next').TFunction<typeof namespace>, i18n: typeof i18n, ready: boolean }}
 */
export function getI18nStore() {
  let namespaces = i18n.options.defaultNS;
  namespaces = typeof namespaces === 'string' ? [namespaces] : namespaces || ['translation'];

  const ready = i18n.isInitialized && namespaces.every(n => i18n.hasLoadedNamespace(n));

  const t = i18n.getFixedT(
    null,
    i18n.options.react?.nsMode === 'fallback' ? namespaces : namespaces[0],
    i18n.options.react?.keyPrefix,
  );

  const ret = /** @type {const} */ ([t, i18n, ready]);
  ret.t = t;
  ret.i18n = i18n;
  ret.ready = ready;
  return ret;
}

let i18nStore = getI18nStore();
const subscribeI18nLanguageChanged = () => {
  if (!i18n.isInitialized) {
    init18n();
  }
  const updateStore = () => {
    i18nStore = getI18nStore();
  };
  i18n.on('languageChanged', updateStore);
  i18n.on('initialized', updateStore);
  return () => {
    i18n.off('languageChanged', updateStore);
    i18n.off('initialized', updateStore);
  };
};

/**
 * @returns {readonly [import('i18next').TFunction<typeof namespace>, typeof i18n, boolean] & { t: import('i18next').TFunction<typeof namespace>, i18n: typeof i18n, ready: boolean }}
 */
export function useTranslation() {
  return useSyncExternalStore(subscribeI18nLanguageChanged, () => i18nStore);
}

/**
 * @param {import('@').Simplify<Omit<import('@').PropsOf<import('./third-party/react-i18next/Trans')['Trans']>, "i18n" | "t">>} props
 */
export const Trans = props => {
  const [t, i18n] = useTranslation();
  return <BaseTrans
    {...props}
    i18n={i18n}
    t={t} />;
};

export default i18n;
