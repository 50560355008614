import { createElement } from 'react';
import CenteredCircularProgress from '../../utils/centered-circular-progress';
import withErrorBoundary from '../../utils/error-boundary';
import lazy from '../../utils/lazy';
import createRemoteModuleLoader from '../../utils/load-remote-module';
import remoteModuleUrlConfigs from '../../utils/remote-url-configs';
import withSuspense from '../../utils/with-suspense';

const loadRemoteDataCenter = createRemoteModuleLoader('mtb_datacenter', 'DATACENTER', remoteModuleUrlConfigs.DATACENTER);

const LazyRemoteDataCenter = lazy(loadRemoteDataCenter);

const RemoteDataCenter = withErrorBoundary(
  withSuspense(LazyRemoteDataCenter, () => createElement(CenteredCircularProgress)),
  'RemoteDataCenter',
);

export { RemoteDataCenter, loadRemoteDataCenter };
