import React from 'react';
import {
  FolderOutlinedIcon,
  FolderOpenedOutlinedIcon,
  FolderSharedOutlinedIcon,
  List,
  Collapse,
} from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../../constants';
import { useTranslation } from '../../../../services/i18n';
import { useStorageExplorer } from '../../hooks';
import { CloudProviderCategoryButton } from '../CloudProviderCategoryButton';
import { CloudProviderHeader } from '../CloudProviderHeader';

export const CloudProviderList = ({ type, providerName, providerIcon }) => {
  const [t] = useTranslation();
  const { type: explorerType } = useStorageExplorer();

  return (
    <List>
      <CloudProviderHeader
        title={providerName}
        type={type}>
        {providerIcon}
      </CloudProviderHeader>
      <Collapse in={explorerType === type}>
        <CloudProviderCategoryButton
          category={CLOUD_STORAGE_CATEGORIES.ALL}
          title={t('connection.all')}>
          <FolderOutlinedIcon />
        </CloudProviderCategoryButton>
        <CloudProviderCategoryButton
          category={CLOUD_STORAGE_CATEGORIES.RECENT}
          title={t('connection.recent')}>
          <FolderOpenedOutlinedIcon />
        </CloudProviderCategoryButton>
        <CloudProviderCategoryButton
          category={CLOUD_STORAGE_CATEGORIES.SHARED}
          title={t('connection.shared')}>
          <FolderSharedOutlinedIcon />
        </CloudProviderCategoryButton>
      </Collapse>
    </List>
  );
};
