import type { ModuleConfig } from '../types';
import config from '../../../config';
import { RemoteWorkspaceOnline, loadRemoteWorkspaceOnline } from './remote';

const CONFIG: ModuleConfig = {
  key            : 'WSO',
  title          : 'wso',
  registeredTitle: 'wsoRegistered',
  icon           : 'WorkspaceIcon',
  card           : {
    title      : 'wsoRegistered',
    description: 'wsoDescription',
    tagline    : 'qualityProject',
    icon       : 'WorkspaceIcon',
    heroIcon   : 'WorkspaceHeroIcon',
  },
  preloadRemoteModule: loadRemoteWorkspaceOnline,
  remoteModule       : RemoteWorkspaceOnline,
  storage            : {
    defaultProjectName: 'wsoDefaultProject',
    defaultExtension  : '.wspx',
    defaultPlaneState : {},
    filter            : ['.wstx', '.wspx', ...(config.feature_flag_ui_v2 ? ['.qcpx'] : [])],
    appRailFilter     : [],
    passthroughFilter : [],
  },
};

export default CONFIG;
