import layout from './layout';
import planes from './planes';
import user from './user';

const selectors = {
  ...layout,
  ...planes,
  ...user,
} as const;

export default selectors;
