import React, { useCallback } from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mtb/ui';
import { useTranslation } from '../../../../services/i18n';
import { useStorageExplorerTable } from '../context';

export const StorageExplorerTableHead = () => {
  const [t] = useTranslation();
  const { columns, sortBy, ascOrder, setTableSort, toggleTableOrder, rows } = useStorageExplorerTable();

  const handleOnClick = useCallback(
    column => () => {
      if (sortBy !== column.id) {
        setTableSort({ sortBy: column.id });
      } else {
        toggleTableOrder();
      }
    },
    [sortBy, setTableSort, toggleTableOrder],
  );

  return (
    <TableHead data-testid="storage-explorer-table-head">
      <TableRow>
        {columns.map(column => (
          <TableCell
            key={column.id}
            align={column.align}
            data-testid={`storage-explorer-table-head-${column.id}`}
            direction={ascOrder ? 'asc' : 'desc'}
            sx={{
              px             : 2,
              py             : 2,
              backgroundColor: t => t.palette.background['200'],
              ...(column.width && { width: column.width }),
            }}>
            <TableSortLabel
              active={sortBy === column.id}
              data-testid={`storage-explorer-table-head-sort-label-${column.id}`}
              direction={ascOrder ? 'asc' : 'desc'}
              disabled={column.disabled || !rows?.length}
              sx={{ padding: 0 }}
              onClick={handleOnClick(column)}>
              <Typography weight="medium">
                {t(column.label)}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
