import type { InitializeDialogsOptions, ShowConfirmCloseDialogHandler, ShowPlanesDialogHandler } from './types';

/**
 * DialogsClient serves as a client to display dialogs in Platform.
 */
class DialogsClient {
  #showMaxPlanesDialog: ShowPlanesDialogHandler = () => Promise.resolve(true);
  #showConfirmCloseDialog: ShowConfirmCloseDialogHandler = () => Promise.resolve(true);

  get showMaxPlanesDialog() {
    return this.#showMaxPlanesDialog;
  }

  get showConfirmCloseDialog() {
    return this.#showConfirmCloseDialog;
  }

  initialize(handlers: InitializeDialogsOptions) {
    this.#showMaxPlanesDialog = handlers?.showMaxPlanesDialog;
    this.#showConfirmCloseDialog = handlers?.showConfirmCloseDialog;
  }
}

const dialogsClient = new DialogsClient();
export default dialogsClient;
