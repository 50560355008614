import React from 'react';
import {
  Stack,
} from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../constants';
import { useStorageExplorer } from '../hooks';
import { StorageExplorerHeader } from '../StorageExplorerHeader';
import { StorageExplorerTable } from '../StorageExplorerTable';
import { ProviderSettings } from './ProviderSettings';

export const StorageExplorerSettingsOrTable = () => {
  const { category, type } = useStorageExplorer();

  return (
    <Stack sx={{ width: '100%', pt: 2, overflowX: 'auto' }}>
      {(category !== CLOUD_STORAGE_CATEGORIES.NONE && type) && (
        <>
          <StorageExplorerHeader />
          <StorageExplorerTable />
        </>
      )}
      {(category === CLOUD_STORAGE_CATEGORIES.NONE) && (
        <ProviderSettings />
      )}
    </Stack>
  );
};
