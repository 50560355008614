import type { ModuleConfigKey } from './modules/types';
import type { PlaneId } from './store/reducers/planes';
import type { InitializeDialogsOptions, InitializeI18nOptions } from '../clients';
import { CloudStorage } from '@mtb/cloud-storage';
import { DialogsClient, I18nClient, LoggerClient, ModuleClient, TelemetryClient } from '../clients';
import config from '../config';
import ModuleConfig from '../module-config';
import * as CONSTANTS from './constants';
import AuthenticationClient from './services/AuthenticationClient';
import EventHandler from './services/EventHandler';
import HealthService from './services/HealthService';
import PlaneService from './services/plane';
import PlatformModule from './services/platform-module';
import RoutingService from './services/routing';
import { PlatformProvider, platformStore, useDispatch, useSelector, useStore } from './store';
import remoteModuleUrlConfigs from './utils/remote-url-configs';

// Let cloud storage know it can initialize and give it the config values.
CloudStorage.initialize(config);

/**
 * Represents the core functionality of Platform
 */
export class PlatformCore {
  #registeredModules = new Map<ModuleConfigKey, ModuleConfig>();

  /**
   * The createNamedLogger function for PlatformClient to create named loggers.
   */
  get createNamedLogger() {
    return LoggerClient.createNamedLogger;
  }

  /**
   * The remote module URL configurations.
   */
  #remoteModuleUrlConfigs: typeof remoteModuleUrlConfigs = remoteModuleUrlConfigs;
  get remoteModuleUrlConfigs() {
    return this.#remoteModuleUrlConfigs;
  }

  /**
   * The constants that are available in the platform.
   */
  get constants() {
    return CONSTANTS;
  }

  /**
   * The modules that are available in the platform.
   */
  get Modules() {
    return ModuleClient;
  }

  /**
   * Returns a function that can be used to get state from the store.
   */
  get getState() {
    return platformStore.getState;
  }

  /**
   * Returns the predefined, memoized selectors for the store.
   */
  get selectors() {
    return platformStore.selectors;
  }

  /**
   * Returns a function that can be used to subscribe changes in the store.
   */
  get subscribe() {
    return platformStore.subscribe;
  }

  /**
   * Returns a function that can be used to dispatch actions to the store.
   */
  get actions() {
    return platformStore.actions;
  }

  /**
   * Returns the useSelector hook from the store.
   */
  get useSelector() {
    return useSelector;
  }

  /**
   * Returns the useDispatch hook from the store.
   */
  get useDispatch() {
    return useDispatch;
  }

  /**
   * Returns the useStore hook from the store.
   */
  get useStore() {
    return useStore;
  }

  /**
   * Returns the Provider component from the store.
   */
  get Provider() {
    return PlatformProvider;
  }

  #dialogs = DialogsClient;
  get Dialogs() {
    return this.#dialogs;
  }

  #i18n = I18nClient;
  get I18n() {
    return this.#i18n;
  }

  #plane = new PlaneService();
  get Plane() {
    return this.#plane;
  }

  #routing = new RoutingService();
  get Routing() {
    return this.#routing;
  }

  #health = new HealthService({ actions: this.actions });
  get Health() {
    return this.#health;
  }

  #eventHandler = new EventHandler();
  get Events() {
    return this.#eventHandler;
  }

  #logger = LoggerClient.createNamedLogger('PlatformCore');
  get Logger() {
    return this.#logger;
  }

  #authenticationClient = AuthenticationClient;
  get AuthenticationClient() {
    return this.#authenticationClient;
  }

  constructor() {
    window.addEventListener('beforeunload', () => {
      const planes = this.getState().planes;
      for (const plane of Object.values(planes)) {
        this.#eventHandler.cleanup(plane);
      }
    });
  }

  /**
   * Initialize Platform with the given options to better
   * control aspects of client behavior when performing inner platform operations.
   * @param options The options to initialize the platform with.
   * @param options.i18n The options to initialize the i18n client with.
   * @param options.dialogs The options to initialize the dialogs client with.
   */
  initialize(options: { i18n: InitializeI18nOptions; dialogs: InitializeDialogsOptions }) {
    I18nClient.initialize(options.i18n);
    DialogsClient.initialize(options.dialogs);
    platformStore.actions.updateLocale(this.I18n.detectLocale());
  }

  /**
   * Sets the authentication client to be used by the platform.
   * @param authClient - The authentication client to be used.
   */
  setAuthClient(authClient: typeof AuthenticationClient) {
    this.#authenticationClient = authClient;
  }

  /**
   * Creates a non auto tracking version of the telemetry client for remote modules.
   * @param clientName - The name of the telemetry client.
   * @returns The remote module telemetry client.
   */
  createTelemetryClient(clientName: string) {
    return TelemetryClient.createClient(clientName);
  }

  /**
   * Creates a module using the given module key.
   * @param moduleKey - The key of the module.
   * @deprecated Use v3 init-remote-module init api instead.
   */
  createModule(moduleKey: ModuleConfigKey) {
    if (this.#registeredModules.has(moduleKey)) {
      return this.#registeredModules.get(moduleKey) as ModuleConfig;
    }

    const module = ModuleConfig.Create(moduleKey, this);

    this.#registeredModules.set(moduleKey, module);
    return module;
  }

  /**
   * @param {ModuleConfig} moduleConfig
   * @deprecated Use v3 init-remote-module init api instead.
   * @todo Remove this function once everyone has switched to the v3 init-remote-module api or higher.
   */
  register(moduleConfig: ModuleConfig) {
    this.Events.register(moduleConfig);
  }

  /**
   * Creates a new PlatformModule instance using the given planeId.
   * @param planeId
   * @returns The created PlatformModule instance.
   */
  createPlatformModule(planeId: PlaneId) {
    if (!planeId) {
      this.#logger.log('PlaneId is required to create a Platform module.');
      return undefined;
    }

    const plane = this.getState().planes[planeId];
    if (!plane) {
      this.#logger.log(`Plane with id ${planeId} does not exist.`);
      return undefined;
    }

    const moduleConfig = ModuleClient.getRemoteModuleConfig(plane.module);
    if (!moduleConfig) {
      this.#logger.log(
        `${plane.module} module configuration does not exist, or is using an earlier version. Skipping Platform module creation...`,
      );
      return undefined;
    }

    const platformModule = new PlatformModule(planeId);
    this.#logger.log(`Created ${moduleConfig.key} platform module`, platformModule);
    return platformModule;
  }
}

const platformCore = new PlatformCore();
export default platformCore;
